<template>
	<div
		class="full-height flex-column justify-space-between bg-gray-light"
	>
		<div
			class="full-height flex-column pa-20 overflow-y-auto"
		>
			<ul class="">
				<li
					v-for="(detail, d_index) in history_list"
					:key="'detail_' + d_index"
					class="box-shadow  pa-10 mb-20"
				>
					<div class="justify-space-between">
						No.{{ d_index + 1 }}
						<div :class="'color-' + detail.p_status_color">{{ detail.p_status_name }}</div>
					</div>

					<div class="mt-10 justify-space-between">
						결제 금액
						<div>{{ detail.pg_amount | makeComma }}원</div>
					</div>
					<div class="mt-10 justify-space-between">
						예정 일시
						<div>{{ detail.sDate}}</div>
					</div>
					<div class="mt-10 justify-space-between">
						결제 일시
						<div>{{ detail.eDate}}</div>
					</div>
					<div class="mt-10" :inner-html.prop="detail.memo | nl2br "></div>
				</li>
			</ul>
			<Empty
				v-if="items.length == 0"
			></Empty>
		</div>
		<div
			v-if="item_detail.order_price > payment_amount"
			class="text-right mt-10"
		>
			<div class="text-center top-line pa-10"> {{ payment_amount | makeComma }} / {{ item_detail.order_price | makeComma }}원</div>
			<button
				@click="onPayment"
				class="btn btn-primary"
			>추가 결제</button>
		</div>
		<div
			v-else-if="item_detail.order_price == item_detail.payment_amount && item_detail.o_status != 2"
			class="text-right mt-10"
		>
			<button
				@click="postPaymentComplete"
				class="btn-inline btn-primary"
			>완료 처리</button>
		</div>
	</div>
</template>

<script>
	import Empty from "../Layout/Empty";
	export default {
		name: 'PaymentDetail'
		, components: {Empty}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '결제내역'
					, title: true
					, bottom_payment: false
					, top: false
					, from: 'PaymentList'
				}
				, item_detail: {

				}
				, items: []
			}
		}
		, computed: {
			history_list: function(){
				return this.items.filter( (item) => {

					switch (item.p_status){
						case '0':
							item.p_status_name = '결제 대기'
							item.p_status_color = ''
							break;
						case '1':
							item.p_status_name = '결제 완료'
							item.p_status_color = 'green'
							break;
						case '2':
							item.p_status_name = '결제 실패'
							item.p_status_color = 'red'
							break;
					}
					return item
				})
			}
			, payment_amount: function(){
				let t = 0
				this.items.filter(( item) => {
					if(item.p_status == 1){
						t += Number(item.pg_amount)
					}
				})
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on')
					let result = await this.$Axios({
						method: 'get'
						, url: '/payment/getPaymentDetail'
						, data: {
							uid: this.$route.params.idx
						}
					})
					if(result.success){
						this.item_detail = result.data
						this.items = result.data.items
					}else{
						throw result.message
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('off')
				}
			}

			, onPayment: function(){
				this.$bus.$emit('to', { name: 'PaymentOrder', params: {idx: this.$route.params.idx }})
			}

			, postPaymentComplete: async function(){

			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}


	}
</script>